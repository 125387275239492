<div class="container mx-6 px-6">
  <article>
    <section class="section is-justify-content-left pb-1">
      <h1>You do not have the necessary<br> permissions to view this page</h1>
    </section>
    <div class="tile mx-5 mb-6">
      <div class="tile is-12 is-justify-content-left">
        <article class="notification is-white is-full-width">
          <div>
            <p>Please <a (click)="login()"><strong>log in again.</strong></a> If that does not work, contact Customer Services.</p>
            <p>Email: <a [href]="'mailto:customerservices@ukho.gov.uk?subject=UKHO%20Data%20Upload%20Permissions%20Issue'"><strong>customerservices@ukho.gov.uk</strong></a></p>
          </div>
        </article>
      </div>
    </div>
  </article>
</div>
